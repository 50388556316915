.App {
  text-align: center;
  height: 100%;
  background-color: #F7F3E3;
}
.Root {
  height: 100vh;
}

.Teams-container {
  padding-top: 50px;
  padding-bottom: 50px;
  color: #A9A9A9;
  display: flex;
  width: 100%;
  height: 100%;
}

.Team {
  border: 5px #A9A9A9;
  height: 100%;
  padding: 20px;
  flex:1 1 20%
}

.TeamCardHeader {
  background-color: #CAEBF2;
}

#create-page {
  margin-top: 100px;
}

.create-page-input-field {
  padding: 30px;
  margin: 10px  !important ;
}

.player-row-removed {
  color: #FF3B3F;
}

.leagueInfo {
  padding: 10px;
}

/* .App-header {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
} 

lightblu #CAEBF2
darker #A9A9A9
Bold red #FF3B3F
neutral #EFEFEF
*/